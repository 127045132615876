@import './brand/red-spark-offers.scss';

@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// // Set your brand colors
// $purple: #8A4D76;
// $pink: #FA7C91;
// $brown: #757763;
// $beige-light: #D0D1CD;
// $beige-lighter: #EFF0EB;


// // Update Bulma's global variables
// $family-sans-serif: "Nunito", sans-serif;
// $grey-dark: $brown;
// $grey-light: $beige-light;
// $primary: $purple;
// $link: $pink;
// $widescreen-enabled: false;
// $fullhd-enabled: false;

// // Update some of Bulma's component variables
// $body-background-color: $beige-lighter;
// $control-border-width: 2px;
// $input-border-color: red;
// $border: red;
// $input-shadow: none;

// $menu-item-hover-background-color: #DCDCDC;
// $menu-label-color: white;

$body-size: 0.9em;
$link: $primary;
$title-family: "Headline";
$box-shadow: "none";
$button-background-color: rgb(11, 11, 11);
$button-border-color: $button-background-color;
$button-color:white;
$button-focus-color: $button-color;
$button-family: "Headline";
$file-cta-background-color: $button-background-color;
$file-cta-color: $button-color;
$file-cta-hover-color: $primary;
$modal-content-width: 80%;
$dropdown-content-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.2), 0 0px 0 1px rgba(10, 10, 10, 0.02);
// Set to match react-select height as that's _really_ hard to change in a generic way everywhere in the app
$control-height: 38px;

@import "../../node_modules/bulma";

@include mobile {
  input.input, textarea.textarea, .select select, div.field.has-addons div.control button.button, div.control {
    font-size: 16px; // Required to prevent auto-focus, because we shring body-size to 0.9em without this, font-size is too small and auto-zoomed
  }
}


$sideNavWidth: 250px;

@font-face {
    font-family: Headline;
    src: URL('../assets/fonts/din1451alt-G.woff2') format('woff2'),
         URL('../assets/fonts/din1451alt-G.woff') format('woff');
}

html {
  overflow-y: auto;
}

.button, a {
  &:disabled, &[disabled], fieldset[disabled] &, fieldset[disabled] &:hover {
    color: #999;
  }
  
  &.is-standout {
    background-color: $standOutButtonBackgroundColor;
    border-color: transparent;
    color: $standOutButtonColor;

    &:hover {
      color: $standOutButtonColor;
    }
}
}
.button, .file-cta {
  text-transform: uppercase;
}
.file-cta {
  font-family: "Headline";
}
.button:hover, .button.is-hovered {
  color:$primary;
}
.right-buttons {
  right:20px;
  float: right;

  &.below-title {
    margin-top:-40px;
  }
}

label.radio {
  & > input[type="radio"] {
    margin-right: 0.5em;
  }
}

.center-buttons {
  position:relative;
  margin-top:30px;
  text-align:center;
  button {
    margin-right:10px;
  }

}

.App {
    margin: 1rem;
    font-family: Arial, Helvetica, sans-serif;
}

.wrapper {
    height: 100vh;
    display: flex;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: white;
  overflow-y: auto;
  // overflow-x: hidden;
}

.main-sticky {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: white;
  overflow-y: auto;
  @media (max-width: 600px) {
    overflow-x: hidden;
  }
}

.sticky-table-container {
  @media only screen and (min-width: 768px) {
    overflow-x: initial;
    overflow-y: initial;
  }
}

.sticky-table-container-affiliate {
  @media only screen and (min-width: 768px) {
    overflow-x: auto;
  }
}

@media only screen and (min-width: 768px) {
  .sticky-headers {
    position: sticky;
    top: 0;
    background: white;
  }
}
.global-search {
  &>div{
    border:none;
  }
}
.table {
  tr {
    height:65px;
  }
  tbody tr td, thead tr th {
    vertical-align: middle;
  }
  thead {
    tr {
      height:auto;

      svg.sort-arrow {
        margin-left:10px;
      }
    }
  }
}
.table .td-response {
  max-width: 600px;
}
.pagination {
  margin-top:20px;
  .page-right {
    margin-left:10px;
  }
}

blockquote p {
  padding: 15px;
}

div.email-builder {
  padding: .75rem 1rem .75rem;
  white-space: pre-wrap;
  ul {
    list-style: disc;
    list-style-position: inside;
  }
  h1 {
    font-size: 3rem !important; }
  
  h2 {
    font-size: 2.5rem !important; }
  
  h3 {
    font-size: 2rem !important; }
  
  h4 {
    font-size: 1.5rem !important; }
  
  h5 {
    font-size: 1.25rem !important; }
  
  h6 {
    font-size: 1rem !important; }
}

h5.title.is-5 {
  text-transform: uppercase;
  font-size: 28px;
}
.title {
  font-size:36px;
  text-transform: uppercase;
  margin-top:20px;
}
.label, .label:not(:last-child) {
  margin-bottom:2px;
}
.tabs ul {
  border-bottom-color: black;
  border-bottom-width: 3px;
}
.tabs a:hover, .tabs a {
  border:none;
}
.tabs li.is-active a {
  color:white;
  background:black;
  font-weight: 800;
}
.content, .tabs {
  margin-top:40px;
}

.modal-card-title {
  flex-shrink: unset;
  .modal-card-subtitle {
    font-size: 0.75em;
    margin-top: 9px;
  }
}

.textarea.is-static {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  caret-color: transparent;
}

a.dropdown-item.disabled {
  color: grey;
  background: none;
  cursor: not-allowed;
  font-style: italic;
}

.badge {
  background: red;
  padding: 0 0.5em;
  color: white;
  font-weight: 800;
  border-radius: 5px;
  text-align: center;
}

@import './login.scss';
@import './nav.scss';
@import './offerdetails.scss';
@import './reporting.scss';
@import './errors.scss';
@import './datepicker.scss';
@import './changelog.scss';